/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";

@import "styles/_encounter-modal.less";
@import "styles/_child-match-modal.less";
@import "styles/_zone-map.less";
@import 'quill1.3.7-table-module/dist/index.css';
@import './admin-portal/cases/case-assign-referral/case-assign-referral.component.css';

@primary: #048E9A;
@primary-contrast: white;
@secondary: #D4DBDC;

/* ************************* Theme styles - start ************************* */

.logo-lg > img {
    background-color: @primary-contrast;
    padding: 2px;
}

.skin-blue .main-header .logo,
.skin-blue .main-header .logo:hover,
.skin-blue .main-header .navbar {
    background-color: @primary;
}

.skin-blue .left-side,
.skin-blue .main-sidebar,
.skin-blue .wrapper {
    background-color: @primary;
}

/* Sidebar text */
.skin-blue .sidebar-menu > li.active > mt-nav-menu-item > a,
.skin-blue .sidebar-menu > li:hover > mt-nav-menu-item > a,
.skin-blue .treeview-menu > li.active > mt-nav-menu-item > a,
.skin-blue .treeview-menu > li > mt-nav-menu-item > a:hover,
.skin-blue .user-panel > .info,
.skin-blue .user-panel > .info > a,
.skin-blue .sidebar a,
.navbar-toggle {
    color: @primary-contrast;
}

/* Sidebar background highlight (no hover)  */
.skin-blue .sidebar-menu > li > .treeview-menu {
    background-color: @primary;
}
/* Sidebar background highlight (hover) */
.skin-blue .sidebar-menu > li.active > mt-nav-menu-item > a,
.skin-blue .sidebar-menu > li:hover > mt-nav-menu-item > a {
    background-color: darken(@primary, 15%) !important;
}

/* Sidebar child nav items */
.skin-blue .sidebar-menu a:hover {
    text-decoration: underline;
}

/* Sidebar child nav list */
.skin-blue .sidebar-menu ul {
    background-color: lighten(@primary, 3%) !important;
}

/* "NAVIGATION" sidebar nav header */
.skin-blue .sidebar-menu > li.header {
    color: @primary-contrast;
    background: @primary;
}

.skin-blue .main-header li.user-header {
    background-color: @primary !important;
    .sign-out-button {
        background-color: White;
        color: @primary;
    }
    p {
        color: @primary-contrast !important;
    }
}

.btn-primary-inverse.active,
.btn-primary-inverse.active:hover,
.btn-primary-inverse.focus.active,
.btn-primary-inverse.focus.active:hover,
.btn-primary-inverse:active:focus,
.btn-primary-inverse:active:hover,
.btn-primary-inverse:hover:not([disabled]) {
    background-color: @primary;
}

/* Mobile view hamburger menu button */
.skin-blue .main-header .navbar .sidebar-toggle:hover {
    background-color: darken(@primary, 15%);
}

.btn-fab-lg,
.btn-fab-md,
.btn-primary,
.btn-success {
    background-color: @primary !important;
    color: white;
    border-color: lightgray;
}

thead {
    background-color: @primary !important;
    color: @primary-contrast !important;
    b {
        color: @primary-contrast !important;
    }
}

/* ************************* Theme styles - end ************************* */

// TODO CCC: figure out better way to do these styles
tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

.app-root-wrapper {
    background-color: @secondary;
    min-height: 100vh;
}

/* Common Utility Classes */

.flex {
    display: flex;
}

.gap-1 {
    gap: 1rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mt-1 {
	margin-top: 1rem;
}

.mr-1 {
 	margin-right: 1rem;
}

.flex-container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding-top: 1%;
}

.referral-form {
    background-color: lightgray;
}

.authorization-form {
    background-color: lightblue;
}

.list {
    .form-check {
        margin-left: 2rem;
    }
    .form-check-label {
        display: none;
    }
}

// add not-split class to any view that you don't want values center aligned
mt-dynamic-view:not(.not-split) > ng-component > div.display-padded > span:first-child {
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

mt-dynamic-view:not(.not-split) > ng-component > div.display-padded > span:nth-child(2) {
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

swal-portal mt-audit-log-row table tr {
  text-align: left;
}

.table>tbody>tr td.alert-column app-alert-badge i {
	 font-size: 18px;
}

.heading-with-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.float-right {
	float: right;
}

swal-portal  mt-dynamic-form-select-wrapper mt-multiselect button#Professions,
swal-portal  mt-dynamic-form-select-wrapper mt-multiselect button#ServiceTypes {
	white-space: wrap;
}

.text-align-center {
	text-align: center;
}
