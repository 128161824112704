.region-marker {
    background-color: white;
    border: 2px solid black;
    padding: 2px;
}

app-provider-zone-map google-map > div {
    position: absolute;
    z-index: 1;
    width: 95% !important;
    border: 1px solid black;
}

.hide-map {
    visibility: hidden;
}