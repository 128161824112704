/* Provider Portal Calendar - Create Encounter Modal */

//#region Create Encounter
/* Case, Start Time, End Time fields */
#swal2-html-container form {
    .encounter-checkboxes {
        font-size: 13.5px;
    }
    .session-notes mt-dynamic-form-input-wrapper > div > span > textarea { // Session Notes field
        height: 15vh;
    }
}

/* Encounter form checkboxes */
#swal2-html-container {
    #Completed, #ParentPresent, #ParentCommunication, #ProvidedViaTeletherapy {
        margin-right: 0.1em;
    }
}
//#endregion

//#region Weekly Recurrence Setting
#swal2-html-container mt-dynamic-field.datepicker mt-dynamic-form-input-wrapper > div > span {
    margin-top: -2rem;
}

#swal2-html-container div.col-md-6.mt-dynamic-field {
    margin-top: -2.2rem;
    text-align: left;
}

#swal2-html-container div.mt-dynamic-field.radio-button {
    margin-top: -1.2rem;
}

#swal2-html-container div.radio-button-align {
    margin-left: 7rem;
}

#swal2-html-container div.radio-button-group {
    margin-top: -2.4rem;
}

#swal2-html-container form.weekly-days-form {
    margin-bottom: -3rem;
}
//#endregion

//#region Monthly Recurrence Setting
#swal2-html-container mt-dynamic-field.recur-option > ng-component > mt-dynamic-form-select-wrapper > div {
    margin-bottom: 0.2em;
}

#swal2-html-container mt-dynamic-field.day-of-week > ng-component > mt-dynamic-form-select-wrapper > div > span > select {
    margin-bottom: -1.3em;
}

#swal2-html-container div.row.inline-flex.monthly-recur {
    margin-left: -2.5em;
    margin-top: -2em;
    margin-bottom: -1em;
}

#swal2-html-container div.row.monthly-recur-group {
    margin-top: -1em;
    text-align: center;
}
//#endregion

//#region Non-Caseload Encounter
#swal2-html-container app-non-caseload-encounter mt-dynamic-form-input-wrapper > div > span > textarea {
    width: 50%;
}
//#endregion

#swal2-html-container {
	overflow: visible;
}
